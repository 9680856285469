
// import NavBar from "@/components/navBar.vue";
import { useRouter} from 'vue-router'
import {ref} from 'vue'
import {productPackage} from '@/api/request.js'
import { NavBar } from 'vant';
export default {
    name:'combo',
  components: {
    [NavBar.name]: NavBar,
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup(){
    const router=useRouter()
     const grList=ref([])
     const swList=ref([])
    const getnumber=()=>{
         router.push({ name: "number" });
    }
    // 套餐
    const getproductPackage=async()=>{
      const {data} =await productPackage()
      console.log(data,'taocan');
      grList.value=data.gr
      swList.value=data.sw
    }
    // 号码页
    const getnumbers=(id:number)=>{
         router.push({path:'/purchase/numbers',query:{id:id}})
    }
    getproductPackage()
     const onClickLeft=()=>{
        router.push({path:'/workbench'})
     }

    return{getnumber, grList, swList, getnumbers, onClickLeft}
  }
};
